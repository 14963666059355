import { ReactEventHandler, useCallback, useEffect, useMemo } from "react";

import { debounce, DebouncedFuncLeading } from "lodash";
import { useAudio } from "react-use";

import { getLogger } from "Services/LoggingService";

export type PreloaderBaseMetadata<T = object> = {
    play: DebouncedFuncLeading<() => void>;
    stop: (checkForLoop: boolean) => void;
} & T;

const logger = getLogger("Revamped Audio Notifications");

export type PreloaderMetadata<T = object> = {
    audioProps: {
        src: string;
        loop?: boolean;
    };
    extraProps?: T;
};
type PreloadedAudioPlayerProps<T> = PreloaderMetadata & {
    msToWaitForSameEventNotifications?: number;
    registerAudioPlayer: (metadata: PreloaderBaseMetadata<T>) => () => void;
    eventsHandler?: Record<string, () => void>;
    children?: React.ReactNode;
};

/**
 * Loads a particular audio notification into the DOM. The player handles its own debouncing to prevent spammy audio.
 * Stopping the audio has the option to only stop if the audio is looping, which is mostly the scenario we care about.
 * It handles stopping itself if the user goes on an active call.
 */
export function PreloadedAudioPlayer<T>({
    audioProps,
    extraProps,
    msToWaitForSameEventNotifications,
    registerAudioPlayer,
    eventsHandler,
    children,
}: PreloadedAudioPlayerProps<T>) {
    const onAudioError = useCallback<ReactEventHandler<HTMLAudioElement>>(
        (evt) => {
            logger.error("Audio notification error", {
                audioProps,
                extraProps,
                error: "error" in evt.target ? evt.target.error : evt,
            });
        },
        [audioProps, extraProps]
    );

    const srcWithCacheBust = useMemo(() => `${audioProps.src}?cache_bust=${Date.now()}`, [audioProps.src]);

    const [audio, , { play, pause, seek }] = useAudio({
        src: srcWithCacheBust,
        loop: audioProps.loop,
        preload: "auto",
        autoPlay: false,
        crossOrigin: "anonymous",
        onError: onAudioError,
        ...(eventsHandler ?? {}),
    });

    const computedKeys = useMemo(() => Object.values(extraProps ?? {}).join("-"), [extraProps]);

    const stopAudio = useCallback(
        (checkForLoop: boolean) => {
            if (checkForLoop && !audioProps.loop) {
                return;
            }
            logger.log("Stopping audio", {
                ...extraProps,
                checkForLoop,
                loop: audioProps.loop,
            });
            pause();
            seek(0);
        },
        // useAudio's functions are not stable even though they work perfectly fine. We can't memoize them.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [audioProps.loop, computedKeys]
    );

    const playAudio = useMemo(
        () =>
            debounce(
                () => {
                    logger.log("Playing audio", { extraProps, audioProps });
                    play();
                },
                msToWaitForSameEventNotifications ?? 0,
                { leading: true }
            ),
        // useAudio's functions are not stable even though they work perfectly fine. We can't memoize them.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [audioProps.loop, computedKeys]
    );

    useEffect(() => {
        const unregister = registerAudioPlayer({
            play: playAudio,
            stop: stopAudio,
            ...extraProps,
        } as PreloaderBaseMetadata<T>);
        return unregister;
    }, [registerAudioPlayer, stopAudio, playAudio, extraProps]);

    return (
        <>
            {audio}
            {children}
        </>
    );
}
